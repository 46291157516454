import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://player.vimeo.com/video/396489073"
    notesSrc="https://www.docdroid.net/tAhsL0y/bible-group-homework-3-78.pdf"
    notesText="Bible Group Homework"
  >
    <SEO title="The Wedding Day - Love Song" />
  </Layout>
)

export default SermonPost
